/** @jsxRuntime classic */
/** @jsx jsx */
import {
    jsx,
    Box,
    Flex,
    Heading,
    Text,
    Label,
    Link,
    Input,
    Button,
} from 'theme-ui';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt as faExternalLink } from '@fortawesome/free-solid-svg-icons';
import useSWR from 'swr';

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

// Crafted exifr bundle to keep size low
import * as exifr from 'exifr/src/core.mjs'; // Empty core import
import { disableAllOptions } from 'exifr/src/highlevel-api.mjs'; // For setting GPS only options
import 'exifr/src/file-readers/BlobReader.mjs'; // For reading from file inputs
import 'exifr/src/file-parsers/jpeg.mjs'; // For parsing jpegs
import 'exifr/src/segment-parsers/tiff-exif.mjs'; // For listing GPS tags
import 'exifr/src/dicts/tiff-gps-keys.mjs'; // For Translating GPS Keys
import 'exifr/src/dicts/tiff-gps-values.mjs'; // For Translating GPS Values

import { API, getTank, swrConfig } from '../api';
import { URLS, ORBITS } from '../constants';
import { FetchProfile } from '../redux/auth.actions';
import FlightDatePicker from '../components/FlightDatePicker';
import PageLayout from '../components/PageLayout';
import MapcallWarning from '../components/MapcallWarning';
import theme from '../theme';
import {
    dateToYMD,
    set,
    toMapcallUrl,
    discourageUserNavigation,
    allowUserNavigation,
    isPilotForST,
    logError,
} from '../utils';
import FourOhFourPage from './404';

// Modeled after `gpsOnlyOptions` from exifr/src/options,
// tweaked to include all GPS fields, not just lon and lat
const EXIFR_OPTIONS = Object.assign({}, disableAllOptions, {
    translateKeys: true,
    translateValues: true,
    gps: true,
    firstChunkSize: 40000,
});

const sx = {
    main: {
        width: '100%',
        maxWidth: '80rem',
        px: 3,
        overflowY: 'scroll',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4,
        py: 3,
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        borderColor: 'backgroundGray',
    },
    name: {
        fontSize: 6,
        fontWeight: 'bold',
    },
    mapcall: {
        fontSize: 3,
        fontWeight: 'bold',
    },
    link: {
        ml: 1,
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: 'primary',
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline',
        },
    },
    linkIcon: {
        ml: 1,
        fontSize: 1,
    },
    field: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'start',
        mb: 3,
        width: '48rem',
    },
    label: {
        mr: 2,
        mt: 1,
        width: '20rem',
        flex: 'none',
    },
    date: {
        flex: 'auto',
        fontSize: '1.6rem',
    },
    optionalTextField: {
        py: 1,
        flex: 'auto',
        fontSize: '1.6rem',
    },
    files: {
        flexDirection: 'row',
        alignItems: 'center',
        bg: 'muted',
        borderColor: 'backgroundGray',
        borderStyle: 'solid',
        borderWidth: '1px',
        p: 2,
        flex: 'auto',
    },
    filesIcon: {
        color: 'disabled',
        mr: 1,
        fontSize: '2rem',
    },
    dragText: {
        fontSize: 3,
        color: 'textGray',
    },
    formButton: {
        width: '20rem',
        fontSize: 3,
        my: 4,
        py: 2,
        px: 5,
    },
    formButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '48rem',
    },
    errors: {
        color: 'severe',
        fontSize: 3,
    },
};

// Styles for Dropzone Uploader using theme settings
const dropzoneStyles = {
    dropzone: {
        border: `1px solid ${theme.colors.backgroundGray}`,
        borderRadius: '0',
        backgroundColor: theme.colors.muted,
        minHeight: theme.space[7],
    },
    inputLabel: {
        fontFamily: theme.fonts.body,
        fontSize: theme.fontSizes[3],
        fontWeight: theme.fontWeights.body,
        color: theme.colors.textGray,
    },
    inputLabelWithFiles: {
        alignSelf: 'auto',
        margin: theme.space[1],
        fontFamily: theme.fonts.body,
        fontSize: theme.fontSizes[3],
        fontWeight: theme.fontWeights.body,
        color: theme.colors.textGray,
        backgroundColor: theme.colors.muted,
    },
    previewContainer: {
        padding: '0 5%',
    },
};

function FileUploader({
    ST,
    tankId,
    flightDate,
    orbit,
    multiple = false,
    onUploadStart,
    onUploadComplete,
}) {
    // For each selected file, get EXIF data and Upload Parameters
    // see https://react-dropzone-uploader.js.org/docs/s3
    const getUploadParams = async ({ file, meta: { name } }) => {
        onUploadStart(orbit);
        const exif = (await exifr.parse(file, EXIFR_OPTIONS)).gps;
        const {
            data: { fields, url },
        } = await API.post(
            `${URLS.flight(ST, tankId, flightDate)}image/upload/`,
            {
                orbit,
                name,
            }
        );
        return { url, fields, meta: { exif } };
    };

    // On every file's status change, if the file is done uploading, then
    // add a database record for it. If all files are done uploading, then
    // call onUploadComplete with the orbit and exif dataset.
    const onChangeStatus = async (
        { meta: { name, exif } },
        status,
        allFiles
    ) => {
        if (status === 'done') {
            API.post(`${URLS.flight(ST, tankId, flightDate)}image/save/`, {
                orbit,
                name,
                exif,
            });

            if (allFiles.every(f => f.meta.status === 'done')) {
                const exifs = allFiles.reduce((acc, f) => {
                    acc[f.meta.name] = f.meta.exif;
                    return acc;
                }, {});
                onUploadComplete(orbit, exifs);
            }
        }
    };

    return (
        <Dropzone
            accept='image/*'
            inputContent='Drag here or click to browse'
            inputWithFilesContent='Add more files'
            canCancel={false}
            canRemove={false}
            addClassNames={{ dropzone: 'tankmagic' }}
            getUploadParams={getUploadParams}
            onChangeStatus={onChangeStatus}
            multiple={multiple}
            maxFiles={multiple ? 500 : 1}
            styles={dropzoneStyles}
        />
    );
}

function AddFlightPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { ST, tankId } = useParams();

    const { data, mutate, error } = useSWR(
        URLS.tank(ST, tankId),
        getTank,
        swrConfig
    );

    const { user } = useSelector(state => state.auth);

    const [date, setDate] = useState(new Date());
    const [workorderId, setWorkorderId] = useState(null);
    const [panoUrl, setPanoUrl] = useState(null);
    const [uploading, setUploading] = useState(false);

    const [flightErrors, setFlightErrors] = useState([]);

    const [flightDate, setFlightDate] = useState(null);
    const [exifs, setExifs] = useState({});

    // NULL indicates empty, as not all groups may get images
    // FALSE indicates currently uploading
    // TRUE indicates all uploaded
    const [uploadedNadir, setUploadedNadir] = useState(null);
    const [uploadedHigh, setUploadedHigh] = useState(null);
    const [uploadedMid, setUploadedMid] = useState(null);
    const [uploadedLow, setUploadedLow] = useState(null);

    // Turn away non-authorized users from adding a flight
    if (!isPilotForST(user, ST)) {
        history.push(`/${ST}/tank/${tankId}`);
    }

    const allUploaded =
        uploadedNadir !== false &&
        uploadedHigh !== false &&
        uploadedMid !== false &&
        uploadedLow !== false;

    const anyUploaded =
        uploadedNadir || uploadedHigh || uploadedMid || uploadedLow;

    if (error) {
        if (error.status === 403) {
            dispatch(FetchProfile());
        } else {
            logError(error);
        }

        if (!data) {
            return <FourOhFourPage subheading={error.subheading} />;
        }
    }

    const navToTank = navToDate => {
        navToDate
            ? history.push(`/${ST}/tank/${tankId}/flight/${navToDate}`)
            : history.push(`/${ST}/tank/${tankId}`);
    };

    const createFlight = async e => {
        e.preventDefault();

        if (flightDate) {
            return;
        }

        const flight_date = dateToYMD(date);

        setFlightErrors([]);
        setUploading(true);

        // Create the flight
        try {
            const { data } = await API.post(`${URLS.tank(ST, tankId)}flight/`, {
                flight_date,
                workorder_id: workorderId,
                pano_url: panoUrl,
            });

            setFlightDate(flight_date);

            if (data?.errors) {
                setFlightErrors(data.errors);
                setWorkorderId(data.workorder_id);
                setPanoUrl(data.pano_url);
            }

            // Discourage user from navigating away while upload in progress
            discourageUserNavigation();
        } catch (e) {
            setFlightErrors(e?.response?.data?.errors);
        }

        setUploading(false);
    };

    const updateExifs = (orbit, exif) => setExifs(set(orbit)(exifs, exif));

    const finishUpload = async () => {
        setUploading(true);
        await API.post(`${URLS.flight(ST, tankId, flightDate)}exifs/`, exifs);
        await API.post(`${URLS.flight(ST, tankId, flightDate)}update_images/`);
        await mutate();
        setUploading(false);
        allowUserNavigation();
        navToTank(flightDate);
    };

    const deleteFlight = async () => {
        if (!window.confirm('Are you sure you want to discard this flight?')) {
            return;
        }

        await API.delete(`${URLS.flight(ST, tankId, flightDate)}`);
        await mutate();

        allowUserNavigation();
        navToTank();
    };

    const showSpinner = uploading || !allUploaded;

    return (
        <PageLayout
            mode='detail'
            title='Add flight'
            isLoading={showSpinner}
            ST={ST}
        >
            <Box sx={sx.main}>
                <Flex as='header' sx={sx.header}>
                    <Heading as='h3' sx={sx.name}>
                        {data?.facilityname}
                    </Heading>
                    <Text sx={sx.mapcall}>
                        Mapcall ID
                        {data?.mapcall_id && (
                            <MapcallWarning>
                                <Link
                                    sx={sx.link}
                                    href={toMapcallUrl(data)}
                                    target='_blank'
                                    title={data.mapcall_id}
                                >
                                    {data.mapcall_id}
                                    <Icon
                                        icon={faExternalLink}
                                        sx={sx.linkIcon}
                                    />
                                </Link>
                            </MapcallWarning>
                        )}
                    </Text>
                </Flex>
                <form onSubmit={createFlight}>
                    <Flex sx={sx.field}>
                        <Label htmlFor='date' sx={sx.label}>
                            Flight date
                        </Label>
                        <Box sx={sx.date}>
                            <FlightDatePicker
                                showArrow={false}
                                value={dateToYMD(date)}
                                onChange={d => {
                                    setDate(d);
                                    setFlightDate(null);
                                    setFlightErrors([]);
                                }}
                                autoFocus
                                disabled={!!flightDate}
                            />
                            <Input id='date' type='hidden' value={date || ''} />
                            {flightErrors?.length > 0 &&
                                flightErrors.map(fe => (
                                    <p key={fe} sx={sx.errors}>
                                        {fe}
                                    </p>
                                ))}
                        </Box>
                    </Flex>
                    <Flex sx={sx.field}>
                        <Label htmlFor='workorder' sx={sx.label}>
                            Work order ID
                        </Label>
                        <Input
                            id='workorder'
                            sx={sx.optionalTextField}
                            value={workorderId || ''}
                            onChange={e => setWorkorderId(e.target.value)}
                            disabled={!!flightDate}
                        />
                    </Flex>
                    <Flex sx={sx.field}>
                        <Label htmlFor='panoUrl' sx={sx.label}>
                            Pano Site Scan URL
                        </Label>
                        <Input
                            id='panoUrl'
                            sx={sx.optionalTextField}
                            value={panoUrl || ''}
                            onChange={e => setPanoUrl(e.target.value)}
                            disabled={!!flightDate}
                        />
                    </Flex>
                    {flightDate && (
                        <Fragment>
                            <Flex sx={sx.field}>
                                <Label htmlFor={ORBITS.NADIR} sx={sx.label}>
                                    Nadir (top-down)
                                </Label>
                                <FileUploader
                                    ST={ST}
                                    tankId={tankId}
                                    flightDate={flightDate}
                                    orbit={ORBITS.NADIR}
                                    onUploadStart={() =>
                                        setUploadedNadir(false)
                                    }
                                    onUploadComplete={(orbit, exif) => {
                                        setUploadedNadir(true);
                                        updateExifs(orbit, exif);
                                    }}
                                />
                            </Flex>
                            <Flex sx={sx.field}>
                                <Label htmlFor={ORBITS.HIGH} sx={sx.label}>
                                    Above tank
                                </Label>
                                <FileUploader
                                    ST={ST}
                                    tankId={tankId}
                                    flightDate={flightDate}
                                    orbit={ORBITS.HIGH}
                                    multiple
                                    onUploadStart={() => setUploadedHigh(false)}
                                    onUploadComplete={(orbit, exif) => {
                                        setUploadedHigh(true);
                                        updateExifs(orbit, exif);
                                    }}
                                />
                            </Flex>
                            <Flex sx={sx.field}>
                                <Label htmlFor={ORBITS.MID} sx={sx.label}>
                                    Mid-tank
                                </Label>
                                <FileUploader
                                    ST={ST}
                                    tankId={tankId}
                                    flightDate={flightDate}
                                    orbit={ORBITS.MID}
                                    multiple
                                    onUploadStart={() => setUploadedMid(false)}
                                    onUploadComplete={(orbit, exif) => {
                                        setUploadedMid(true);
                                        updateExifs(orbit, exif);
                                    }}
                                />
                            </Flex>
                            <Flex sx={sx.field}>
                                <Label htmlFor={ORBITS.LOW} sx={sx.label}>
                                    Below tank
                                </Label>
                                <FileUploader
                                    ST={ST}
                                    tankId={tankId}
                                    flightDate={flightDate}
                                    orbit={ORBITS.LOW}
                                    multiple
                                    onUploadStart={() => setUploadedLow(false)}
                                    onUploadComplete={(orbit, exif) => {
                                        setUploadedLow(true);
                                        updateExifs(orbit, exif);
                                    }}
                                />
                            </Flex>
                        </Fragment>
                    )}

                    {!flightDate && (
                        <Flex sx={sx.formButtons}>
                            <Button
                                type='button'
                                variant={'secondaryCta'}
                                sx={sx.formButton}
                                onClick={() => navToTank()}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                variant={
                                    !date || uploading ? 'disabled' : 'cta'
                                }
                                sx={sx.formButton}
                                disabled={!date || uploading}
                            >
                                Create Flight
                            </Button>
                        </Flex>
                    )}
                    {flightDate && (
                        <Flex sx={sx.formButtons}>
                            <Button
                                type='button'
                                variant={'secondaryCta'}
                                sx={sx.formButton}
                                onClick={deleteFlight}
                            >
                                Discard flight
                            </Button>
                            <Button
                                type='button'
                                variant={
                                    !anyUploaded || !allUploaded
                                        ? 'disabled'
                                        : 'cta'
                                }
                                sx={sx.formButton}
                                disabled={!anyUploaded || !allUploaded}
                                onClick={finishUpload}
                            >
                                Save flight
                            </Button>
                        </Flex>
                    )}
                </form>
            </Box>
        </PageLayout>
    );
}

export default AddFlightPage;
