/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment, useState } from 'react';
import {
    jsx,
    Flex,
    Heading,
    IconButton,
    Close,
    Spinner,
    Card,
    NavLink,
    Text,
} from 'theme-ui';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
    faUserCircle,
    faEllipsisH,
    faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import SearchTabs from './SearchTabs';
import { ReactComponent as Logo } from '../img/Logo.svg';
import { ReactComponent as AddFlight } from '../img/AddFlight.svg';
import { APP_NAME, URLS, JOB_STATUS } from '../constants';
import { Logout } from '../redux/auth.actions';
import { ForbidAutoZoom } from '../redux/map.actions';
import { API } from '../api';
import STSelector from './STSelector';
import { ChangeAppST } from '../redux/st.actions';
import { isPilotForST, isAdminForST } from '../utils';

const sx = {
    appBar: {
        position: 'relative',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        bg: 'primary',
        px: 3,
        height: '6.4rem',
        color: 'white',
    },
    logo: {
        flex: 'none',
        width: '3.2rem',
        color: '#fff',
        mr: 3,
    },
    appName: {
        flex: 'none',
        fontSize: 6,
        lineHeight: 1,
        fontWeight: 'bold',
        color: 'white',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    stSelector: {
        ml: 4,
        bg: '#ffffff33',
        border: 0,
        fontSize: '1.6rem',
    },
    title: {
        flex: 'none',
        fontSize: 6,
        lineHeight: 1,
        fontWeight: 'normal',
        color: '#fff',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    controls: {
        ml: 'auto',
        flex: 'none',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flexEnd',
    },
    emailButton: {
        ml: 6,
        flex: 'none',
        fontSize: '2.4rem',
        color: '#fff',
        ':hover': {
            color: '#fff',
        },
        ':active': {
            color: '#fff',
        },
        ':visited': {
            color: '#fff',
        },
    },
    menuButton: {
        ml: 6,
        flex: 'none',
        fontSize: '2.4rem',
        color: '#fff',
    },
    addFlightButton: {
        ml: 6,
        flex: 'none',
        color: '#fff',
        width: '3.6rem',
        height: '3.6rem',
    },
    addFlightIcon: {
        color: '#fff',
        width: '100%',
    },
    spinner: {
        color: '#fff',
        size: '3.2rem',
        strokeWidth: 3,
    },
    close: {
        ml: 8,
        flex: 'none',
        color: '#fff',
    },
    menu: {
        position: 'absolute',
        right: 2,
        top: '90%',
        minWidth: '10.4rem',
        bg: 'background',
        boxShadow: 2,
        color: 'text',
    },
    adminMenu: {
        position: 'absolute',
        right: 8,
        top: '90%',
        minWidth: '10.4rem',
        bg: 'background',
        boxShadow: 2,
        zIndex: 1,
        color: 'text',
    },
    email: {
        display: 'block',
        fontWeight: 'normal',
        lineHeight: 2.4,
        px: 2,
        py: 1,
        borderStyle: 'solid',
        borderWidth: 0,
        borderColor: 'divider',
        borderBottomWidth: '1px',
    },
    menuLink: {
        display: 'block',
        fontWeight: 'normal',
        lineHeight: 2.4,
        px: 2,
        py: '0.4rem',
        color: 'primary',
        ':hover': {
            bg: 'secondary',
            color: 'primary',
        },
    },
    menuDivider: {
        py: 1,
        borderStyle: 'solid',
        borderWidth: 0,
        borderColor: 'divider',
        borderTopWidth: '1px',
    },
    menuText: {
        display: 'block',
        fontWeight: 'normal',
        lineHeight: 2.4,
        px: 2,
        py: '0.4rem',
    },
};

function SearchBar({
    isLoading,
    onLogout,
    user,
    forbidAutoZoom,
    ST,
    STDetail,
    STList,
    changeST,
    ...props
}) {
    const [showMenu, setShowMenu] = useState(false);
    const { push } = useHistory();
    const { pathname } = useLocation();

    const onTabChange = tab => {
        forbidAutoZoom();
        push(tab);
    };

    // The route is the source of ST truth
    const onSelectST = ST => {
        changeST(ST);
        push(URLS.homepage(ST));
    };

    return (
        <Flex as='header' sx={sx.appBar} {...props}>
            <Logo sx={sx.logo} />
            <Heading as='h1' sx={sx.appName}>
                {APP_NAME}
            </Heading>
            <STSelector
                sx={sx.stSelector}
                selectedST={ST}
                onSelectST={onSelectST}
                STList={STList}
            />
            <Flex sx={sx.controls}>
                {isLoading && <Spinner sx={sx.spinner} />}
                <SearchTabs active={pathname} onChange={onTabChange} />
                <IconButton
                    onClick={() => setShowMenu(isShowing => !isShowing)}
                    sx={sx.menuButton}
                    aria-label='Account menu'
                >
                    <Icon icon={faUserCircle} />
                </IconButton>
                {showMenu && (
                    <Card sx={sx.menu}>
                        <Text sx={sx.email}> {user?.email || 'Unknown'}</Text>
                        {isAdminForST(user, ST) && (
                            <NavLink
                                sx={sx.menuLink}
                                href='/admin/'
                                target='_blank'
                            >
                                Administer
                            </NavLink>
                        )}
                        <NavLink sx={sx.menuLink} onClick={onLogout} href='#'>
                            Sign out
                        </NavLink>
                    </Card>
                )}
            </Flex>
        </Flex>
    );
}

function DetailBar({
    user,
    title,
    onClose,
    onAddFlight,
    isUploading,
    isLoading,
    onLogout,
    ST,
    tank,
    flight,
    onChange,
    forbidAutoZoom,
    email,
    admin,
    ...props
}) {
    const [showMenu, setShowMenu] = useState(false);
    const [fetchingAllTanks, setFetchingAllTanks] = useState({
        fetching: false,
        error: false,
    });

    const emailTankHref = () => {
        if (tank) {
            const tankUrl = `${window.location.origin}/${ST}/tank/${tank.id}`;
            const body = encodeURIComponent(`${tank.facilityname}: ${tankUrl}`);
            const subject = encodeURIComponent(
                `${APP_NAME} ${tank.facilityname}`
            );

            // Open email client with body and subject but empty to field
            return `mailto:?to=%20&body=${body}&subject=${subject}`;
        }

        return '';
    };

    const reselectCardinalImages = async () => {
        await API.post(
            `${URLS.flight(ST, tank.id, flight.flight_date)}update_images/`
        );
        onChange();
        setShowMenu(false);
    };

    const reprocessFlightCondition = async () => {
        await API.post(`${URLS.flight(ST, tank.id, flight.flight_date)}score/`);
        onChange();
        setShowMenu(false);
    };

    const fetchResultsForAllTanks = () => {
        setFetchingAllTanks({ fetching: true, error: false });
        API.post(URLS.fetchResultsForAllTanks(ST))
            .then(() => {
                setFetchingAllTanks({ fetching: false, error: false });
                onChange();
                setShowMenu(false);
            })
            .catch(() => setFetchingAllTanks({ fetching: false, error: true }));
    };

    const fetchResultsForAllTanksButton = () => {
        if (fetchingAllTanks.fetching) {
            return <Text sx={sx.menuText}>Fetching Results for All Tanks</Text>;
        }

        if (fetchingAllTanks.error) {
            return (
                <Text sx={sx.menuText}>
                    Failed Fetching Results for All Tanks
                </Text>
            );
        }

        return (
            <NavLink
                sx={sx.menuLink}
                onClick={fetchResultsForAllTanks}
                href='#'
                disabled
            >
                Fetch Results For All Tanks
            </NavLink>
        );
    };

    const reconditionAllFlights = async () => {
        await API.post(URLS.reconditionAllFlights(ST));
        onChange();
        setShowMenu(false);
    };

    return (
        <Flex as='header' sx={sx.appBar} {...props}>
            <Heading as='h2' sx={sx.title}>
                {title}
            </Heading>
            <Flex sx={sx.controls}>
                {isLoading && <Spinner sx={sx.spinner} />}
                {isPilotForST(user, ST) && onAddFlight && (
                    <IconButton
                        sx={{
                            ...sx.addFlightButton,
                            cursor: isUploading ? 'default' : 'pointer',
                        }}
                        aria-label='Add a flight'
                        onClick={onAddFlight}
                        disabled={!!isUploading}
                    >
                        {isUploading ? (
                            <Spinner sx={sx.spinner} title='Uploading' />
                        ) : (
                            <AddFlight
                                sx={sx.addFlightIcon}
                                title='Add a flight'
                                onClick={onAddFlight}
                            />
                        )}
                    </IconButton>
                )}
                {email && (
                    <NavLink
                        sx={sx.emailButton}
                        href={emailTankHref()}
                        target='_blank'
                    >
                        <Icon icon={faEnvelope} />
                    </NavLink>
                )}
                {isAdminForST(user, ST) && admin && (
                    <Fragment>
                        <IconButton
                            onClick={() => setShowMenu(isShowing => !isShowing)}
                            sx={sx.menuButton}
                            aria-label='Admin menu'
                        >
                            <Icon icon={faEllipsisH} />
                        </IconButton>
                        {tank && showMenu && (
                            <Card sx={sx.adminMenu}>
                                <NavLink
                                    sx={sx.menuLink}
                                    href={`/admin/api/tank/${tank.id}/change/`}
                                >
                                    Edit Tank
                                </NavLink>
                                {flight && (
                                    <Fragment>
                                        <NavLink
                                            sx={sx.menuLink}
                                            href={`/admin/api/flight/${flight.id}/change/`}
                                        >
                                            Edit Flight
                                        </NavLink>
                                        <Text
                                            sx={{
                                                ...sx.menuText,
                                                ...sx.menuDivider,
                                            }}
                                        >
                                            This Flight:
                                        </Text>
                                        <NavLink
                                            sx={sx.menuLink}
                                            onClick={reselectCardinalImages}
                                            href='#'
                                        >
                                            Reselect Cardinal Images
                                        </NavLink>
                                        {flight.processing ===
                                        JOB_STATUS.STARTED ? (
                                            <Text sx={sx.menuText}>
                                                Processing Flight
                                            </Text>
                                        ) : (
                                            <NavLink
                                                sx={sx.menuLink}
                                                onClick={
                                                    reprocessFlightCondition
                                                }
                                                href='#'
                                                disabled
                                            >
                                                {flight.condition &&
                                                flight.condition !== 'unknown'
                                                    ? 'Reprocess'
                                                    : 'Process'}{' '}
                                                Flight Condition
                                            </NavLink>
                                        )}
                                    </Fragment>
                                )}
                                <Text
                                    sx={{ ...sx.menuText, ...sx.menuDivider }}
                                >
                                    All Tanks:
                                </Text>

                                {fetchResultsForAllTanksButton()}
                                <NavLink
                                    sx={sx.menuLink}
                                    onClick={reconditionAllFlights}
                                    href='#'
                                    disabled
                                >
                                    Recalculate Condition for All Flights
                                </NavLink>
                            </Card>
                        )}
                    </Fragment>
                )}
                {onClose && (
                    <Close
                        sx={sx.close}
                        aria-label='Close'
                        onClick={() => {
                            forbidAutoZoom();
                            onClose();
                        }}
                    />
                )}
            </Flex>
        </Flex>
    );
}

function AppBar({ mode, user, ...props }) {
    if (mode === 'detail') return <DetailBar user={user} {...props} />;

    return <SearchBar user={user} {...props} />;
}

const mapStateToProps = ({ auth: { user }, st: { STDetail, STList } }) => ({
    user,
    STDetail,
    STList,
});

const mapDispatchToProps = dispatch => ({
    onLogout: () => dispatch(Logout()),
    forbidAutoZoom: () => dispatch(ForbidAutoZoom()),
    changeST: ST => dispatch(ChangeAppST(ST)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
